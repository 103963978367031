import axios from 'axios';
import {ApiError} from './type';

// Each server should have different apiErrorCatcher
export const apiErrorCatcher = (e: unknown) => {
  let apiError: ApiError = {
    type: 'API',
    data: {
      baseURL: undefined,
      path: undefined,
      message: undefined,
      status: undefined,
    },
  };
  if (axios.isAxiosError(e)) {
    apiError = {
      ...apiError,
      data: {
        ...(e?.response?.config?.baseURL && {
          baseURL: e?.response?.config?.baseURL,
        }),
        ...(e?.response?.config?.url && {path: e?.response?.config?.url}),
        ...(e?.response?.data?.message && {
          message: e?.response?.data?.message,
        }),
        ...(e?.response?.status && {status: e?.response?.status?.toString()}),
      },
    };
  }
  return apiError;
};
