import React from "react";
import "./Support_Section_1.scss";
import "./../../styles/common.scss";

function Support_Section_1() {
  return (
    <div className={"support-section-1 FLEX_CENTER"}>
      <div
        className={
          "support-section-1-container container FLEX_COLUMN_ALIGN_CENTER JUSTIFY_CENTER"
        }
      >
        <p className="support-section-1-container-subtitle">
          If you would like a summary of the products we launched,
          behind-the-scenes updates, or team selections, please email us
        </p>

        <p className="support-section-1-container-title">
          The best through business partnerships Become a Web3 Business Partner
        </p>

        <a
          className="support-section-1-container-btn FLEX_CENTER"
          href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`}
        >
          Send Email
        </a>
      </div>
    </div>
  );
}

export default Support_Section_1;
