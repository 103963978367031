import React from 'react';
import './About_Section_1.scss';
import Section_Title from '../../molcules/Section_Title';
import team_image_1 from '../../assets/image/about-section-1/1.jpg'
import team_image_2 from '../../assets/image/about-section-1/2.jpg'
import team_image_3 from '../../assets/image/about-section-1/3.jpg'
import team_image_4 from '../../assets/image/about-section-1/4.jpg'
import team_image_5 from '../../assets/image/about-section-1/5.jpg'
import team_image_6 from '../../assets/image/about-section-1/6.jpg'
import team_image_7 from '../../assets/image/about-section-1/7.jpg'
import team_image_8 from '../../assets/image/about-section-1/8.jpg'
import team_image_9 from '../../assets/image/about-section-1/9.jpg'
import team_image_10 from '../../assets/image/about-section-1/10.jpg'

const topImages = [
    team_image_1,
    team_image_2,
    team_image_3,
    team_image_4,
    team_image_5,
]

const bottomImages = [team_image_6, team_image_7, team_image_8, team_image_9, team_image_10]

function About_Section_1() {

    return (
        <div className='hidden-area'>
            <div className={'about-section-1 container'}>
                <Section_Title text={'Our Mission'}/>
                <div className='about-section-1-sub-title-row'>
                    <a href={'#our-priciple'} className='about-section-1-sub-title'>Our Principles</a>
                    <a href={'#core-contributors'} className='about-section-1-sub-title'>Core Contributors</a>
                </div>
                <div className='about-section-1-content'>
                    {`Whisper strives to be a secure communication tool that upholds human rights.\nDesigned to enable the free flow of information,\nprotect the right to private, secure conversations, and promote the sovereignty of individuals.`}
                </div>
                <div className='about-section-1-image-flow'>
                    <div className='about-section-1-image-flow-row top'>
                        {topImages.concat(topImages).concat(topImages).map((image, index) => <img src={image} key={`top_${index}`} alt={''}/>)}
                    </div>
                    <div className='about-section-1-image-flow-row bottom'>
                        {bottomImages.concat(bottomImages).concat(bottomImages).map((image, index) => <img src={image} key={`top_${index}`} alt={''}/>)}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default About_Section_1;
