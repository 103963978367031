import React from 'react';
import './Home_Section_1.scss';
import whisper_app from '../../assets/image/home-section-1/whisper-app.png';
import external_bc from '../../assets/image/home-section-1/external-bc.png';
import connect_wallet_qr from '../../assets/image/home-section-1/connect-wallet-qr.png';
import secure from '../../assets/image/home-section-1/secure.png';
import Stores from "../../molcules/Stores";


function Home_Section_1() {

    return (
        <div className={'home-section-1'}>
            <div className={'home-section-1-container container'}>
                <div className={'home-section-1-container-image-container'}>
                    <img className={'home-section-1-container-image-container-image-1'} src={whisper_app} alt={'whisper'}/>
                    <img className={'home-section-1-container-image-container-image-2'} src={external_bc} alt={'external_bc'}/>
                    <img className={'home-section-1-container-image-container-image-3'} src={connect_wallet_qr} alt={'connect_wallet'}/>
                    <img className={'home-section-1-container-image-container-image-4'} src={secure} alt={'secure'}/>
                </div>
                <div className={'home-section-1-container-info-container'}>
                    <div className={'home-section-1-container-info-container-title'}>
                        Suggesting new<br/>
                        paradigm for Web 3.0
                    </div>
                    <div className={'home-section-1-container-info-container-desc'}>
                        Center of Web 2.0 and <br/>
                        Web 3.0 with extreme scalability
                    </div>
                    <div className={'home-section-1-stores'} style={{marginTop: 73}}>
                        <Stores />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home_Section_1;
