export const FAQs = [
  {
    FAQ_category_id: 1,
    FAQ_category_name: "General",
    FAQ_id: 1,
    description:
      "Click the Sign up and fill up the nickname that you want to use in Whisper. Set the PIN code. In order to use Whisper wallet, press Back up now and follow the process.",
    title: "How can I register Whisper?",
  },
  {
    FAQ_category_id: 1,
    FAQ_category_name: "General",
    FAQ_id: 2,
    description:
      "Of course, Yes. Whisper is a 100% free messenger service. There are no advertisements in the application as well.",
    title: "Is Whisper free to use?",
  },
  {
    FAQ_category_id: 1,
    FAQ_category_name: "General",
    FAQ_id: 3,
    description:
      "Nowadays, messenger platform services are eating up the users’ personal information for their favor. As the solution for the growing social issue, such policies like GDPR regulate the privacy issue. As users never register their personal information in Whisper, we are not available to use it for the business. Moreover, Whisper has the auto-destructive feature as default which means, the messages that users send are automatically deleted.",
    title: "Why Whisper special from other messengers?",
  },
  {
    FAQ_category_id: 1,
    FAQ_category_name: "General",
    FAQ_id: 4,
    description:
      "Whisper is an ultimate secure messenger that is built to secure users’ privacy under the slogan, ‘Your privacy is our priority.' Whisper doesn’t require any privately identifiable information to register.",
    title: "What is Whisper?",
  },
  {
    FAQ_category_id: 2,
    FAQ_category_name: "Messenger",
    FAQ_id: 5,
    description:
      "Mnemonic code is a unique personal code for each user. The original long and uncomfortable code is replaced with a list of random words for user comfort. It is crucial for your security; it is better to write down the list of random words safely.",
    title: "How does Mnemonic code work?",
  },
  {
    FAQ_category_id: 2,
    FAQ_category_name: "Messenger",
    FAQ_id: 6,
    description:
      "The unseen messages are on the blockchain engine when users send. However, when users confirm or reply to the messages, it is automatically deleted.",
    title: "Where are my messages stored?",
  },
  {
    FAQ_category_id: 2,
    FAQ_category_name: "Messenger",
    FAQ_id: 7,
    description:
      "The below link will kindly introduce the process. https://youtu.be/xuRFZKWdPPQ",
    title: "How to send a message?",
  },
  {
    FAQ_category_id: 3,
    FAQ_category_name: "Wallet",
    FAQ_id: 8,
    description:
      "Whisper wallet allows you to send digital assets based on blockchain. To send assets, click the wallet tab at the bottom. Enter the certain assets to send. Then, press send button to continue the process.",
    title: "How can I send assets?",
  },
  {
    FAQ_category_id: 3,
    FAQ_category_name: "Wallet",
    FAQ_id: 9,
    description:
      "The other blockchain-based assets are supported in the Whisper wallet via EQBR’s own blockchain technology.",
    title: "What kind of assets do you support?",
  },
  {
    FAQ_category_id: 3,
    FAQ_category_name: "Wallet",
    FAQ_id: 10,
    description:
      "Currently, there is no transaction fee occurred so that users can comfortably use Whisper.",
    title: "Is there any transaction fee?",
  },
  {
    FAQ_category_id: 3,
    FAQ_category_name: "Wallet",
    FAQ_id: 11,
    description:
      "Wallet address has 42 characters and starts with 0x. You can send and receive digital assets by sharing this address in the form of a QR code or text.",
    title: "What is my wallet address?",
  },
  {
    FAQ_category_id: 3,
    FAQ_category_name: "Wallet",
    FAQ_id: 12,
    description:
      "Through the blockchain engine, the keys generated from the wallet are stored and encrypted to local storage. Only you can access the wallet.",
    title: "Is the wallet safe?",
  },
  {
    FAQ_category_id: 4,
    FAQ_category_name: "Easy Swap",
    FAQ_id: 13,
    description:
      "“Swap” means exchange, in which a contracting party exchanges a particular currency for another currency. In Whisper, Easy Swap refers to adding one more step on “Swap” which directly exchanges the currency from one network to another.",
    title: "What function is Easy Swap?",
  },
  {
    FAQ_category_id: 4,
    FAQ_category_name: "Easy Swap",
    FAQ_id: 14,
    description:
      "See the link below for a detailed description of “Easy Swap” in Whisper MSG. https://medium.com/@Volare_Official/how-to-swap-eth-volr-in-whisper-f401089d91e3",
    title: "How to Easy Swap?",
  },
  {
    FAQ_category_id: 4,
    FAQ_category_name: "Easy Swap",
    FAQ_id: 15,
    description:
      "Assume exchanging tokens from “A” network and tokens from “B” network. 1. Gateway fee from “A” Network to DEX Network When the user confirms the swap, the gateway solution wraps the token from “A” network and moves to the DEX network. 2. Swap transaction fee “A” tokens are unwrapped on DEX network and exchanged. Swap transaction fee follows the fee policy of Acroswap, a service provider of DEX. 3. Gateway Fee From DEX Network to “B” Network The exchanged “B” tokens are moved to the “B” network in a wrapped state through the gateway solution. The moved “B” tokens are unwrapped and remain in the “B” network. Total fee : Gateway Fee + 2 Times and Swap transaction fee + 1 Time Total fee charged in Whisper Easy Swap is 0.698236733% + DEX fee.",
    title: "Fee Policy",
  },
  {
    FAQ_category_id: 4,
    FAQ_category_name: "Easy Swap",
    FAQ_id: 16,
    description:
      "The name of DEX, which interchanges tokens from different networks, is Acroswap. Acroswap provides Swap functionality within Whisper messenger.",
    title: "What is Acroswap?",
  },
];
