import React from 'react';
import './About.scss';
import About_Section_1 from '../components/about/About_Section_1';
import About_Section_2 from '../components/about/About_Section_2';
import About_Section_3 from '../components/about/About_Section_3';

function About() {

    return (
        <div id={'about'}>
            {/* <About_Section_1/> */}
            <About_Section_2/>
            <About_Section_3/>
        </div>
    )
}

export default About;
