import React from 'react';

function NotFound(props) {

  return (
    <div>
      <div className='container'>
        Not Found
      </div>
    </div>
  )
}

export default NotFound;
