import {useEffect, useMemo} from "react";
import {useMediaQuery} from "react-responsive";

const scrollListenerForAnimation = (className, pxFromBottom) => {
    const elements = document.getElementsByClassName(className);
    const viewportHeight = window.innerHeight;
    for (let i = 0; i < elements.length; ++i) {
        const element = elements[i];
        const distanceFromViewportTop = element.getBoundingClientRect().top;
        const distanceFromViewportBottom = element.getBoundingClientRect().bottom;
        const isVisible =
            viewportHeight >= distanceFromViewportTop + pxFromBottom &&
            distanceFromViewportBottom > 0;
        const animations = element.getAnimations();
        if(isVisible){
            animations.forEach(el => {
                el.play();
            })
        }
    }
}

const startAnimationByScroll = (className, pxFromBottom) => {
    const listener = ()=>scrollListenerForAnimation(className, pxFromBottom);
    window.addEventListener('scroll', listener);
    return listener;
}

export default function useScrollAnimation (classNames, pxFromBottom = 300) {
    const isSmall = useMediaQuery({
        query: "(min-width:0px) and (max-width:834px)",
    });
    const isMedium = useMediaQuery({
        query: "(min-width:834px) and (max-width:1280px)",
    });
    const isExtraLarge = useMediaQuery({
        query: "(min-width:1280px)",
    });
    const viewSize = useMemo(() => {
        if (isSmall) {
            return 'small'
        }
        if (isMedium) {
            return 'medium'
        }
        if (isExtraLarge) {
            return 'extra-large'
        }
        return 'small'
    }, [isSmall, isMedium, isExtraLarge])
    useEffect(()=>{
        let pxFromBottomWithSize = pxFromBottom - 400;
        switch (viewSize) {
            case 'small':
                pxFromBottomWithSize = pxFromBottomWithSize - 300;
                break;
            case 'medium':
                pxFromBottomWithSize = pxFromBottomWithSize - 100;
                break;
            case 'extra-large':
                break;
            default:
                break;
        }
        const listeners = classNames.map(className => {
            const listener = startAnimationByScroll(className, pxFromBottomWithSize)
            return listener;
        })
        return ()=> {
            listeners.forEach(el => {
                window.removeEventListener('scroll', el)
            })
        };
    },[viewSize])
}
