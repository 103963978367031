import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Layout } from "./Layout";
import Home from "../page/Home";
import NotFound from "../components/NotFound";
import About from "../page/About";
import Support from "../page/Support";
import Download from "../page/Download";

export const RootRouter = () => {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0,0)
  }, [location.pathname])

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={"/"} element={<Home />} />
        <Route path={"/about"} element={<About />} />
        <Route path={"/support"} element={<Support />} />
        <Route path={"/download"} element={<Download />} />
        <Route path={"*"} element={<NotFound />} />
      </Route>
    </Routes>
  );
};
 