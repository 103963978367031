import React, { useState } from "react";
import "./Download_Section_1.scss";
import "./../../styles/common.scss";
import { useMediaQuery } from "react-responsive";
import backgroundImageSmall from "./../../assets/image/download-section-1/download-1-background-small.svg";
import backgroundImageMedium from "./../../assets/image/download-section-1/download-1-background-medium.svg";
import backgroundImageLarge from "./../../assets/image/download-section-1/download-1-background-large.svg";
import androidDevice from "./../../assets/image/download-section-1/device-android.svg";
import iosDevice from "./../../assets/image/download-section-1/device-ios.svg";
import androidIconBlue from "./../../assets/image/download-section-1/android-icon-blue.svg";
import androidIconGray from "./../../assets/image/download-section-1/android-icon-gray.svg";
import appleIconBlue from "./../../assets/image/download-section-1/apple-icon-blue.svg";
import appleIconGray from "./../../assets/image/download-section-1/apple-icon-gray.svg";
import Stores from "../../molcules/Stores";
import chromeIconBlue from "./../../assets/image/download-section-1/chrome-icon-blue.svg";
import chromeIconGray from "./../../assets/image/download-section-1/chrome-icon-gray.svg";

const type = [
  {
    name: "Android",
    unSelectedImage: androidIconGray,
    selectedImage: androidIconBlue,
  },
  {
    name: "IOS",
    unSelectedImage: appleIconGray,
    selectedImage: appleIconBlue,
  },
  // {
  //   name: "Chrome",
  //   unSelectedImage: chromeIconGray,
  //   selectedImage: chromeIconBlue,
  // },
];

function Download_Section_1() {
  const isSmall = useMediaQuery({ query: "(max-width: 834px)" });
  const isMedium = useMediaQuery({ query: "(max-width: 1280px)" });
  let backgroundImage;
  if (isSmall) {
    backgroundImage = backgroundImageSmall;
  } else if (isMedium) {
    backgroundImage = backgroundImageMedium;
  } else {
    backgroundImage = backgroundImageLarge;
  }
  const [downloadType, setDownloadType] = useState("Android");

  let componentToRender;
  if (isSmall) {
    switch (downloadType) {
      case "Android":
        componentToRender = <TypeMobileSmall downloadType={downloadType} />;
        break;
      case "IOS":
        componentToRender = <TypeMobileSmall downloadType={downloadType} />;
        break;

      case "Chrome":
        // return <TypeChrome />;
        componentToRender = <TypeMobileSmall downloadType={downloadType} />;
        break;
      default:
        return;
    }
  } else {
    switch (downloadType) {
      case "Android":
        componentToRender = <TypeMobile downloadType={downloadType} />;
        break;
      case "IOS":
        componentToRender = <TypeMobile downloadType={downloadType} />;
        break;
      case "Chrome":
        // return <TypeChrome />;
        componentToRender = <TypeMobile downloadType={downloadType} />;
        break;
      default:
        return;
    }
  }

  const selectType = (type) => {
    if (type === "Chrome") {
      return;
    }
    setDownloadType(type);
  };

  return (
    <div className={"download-section-1 FLEX_CENTER"}>
      <div
        className={
          "download-section-1-container container FLEX_COLUMN_ALIGN_CENTER JUSTIFY_CENTER"
        }
      >
        <img
          className={"download-section-1-container-img"}
          src={backgroundImage}
          alt="section-1-background"
        />
        <div className="download-section-1-container-title">
          Download everthing you need
        </div>
        <div className="download-section-1-container-select FLEX_ROW SPACE_BETWEEN">
          {type.map((el, index) => {
            return (
              <div
                className={
                  downloadType === el.name
                    ? "download-section-1-container-select-btn FLEX_CENTER selected "
                    : "download-section-1-container-select-btn FLEX_CENTER"
                }
                onClick={() => selectType(el.name)}
                key={index}
              >
                {downloadType === el.name ? (
                  <img
                    src={el.selectedImage}
                    alt={`${el.name}-icon`}
                    className="download-section-1-container-select-btn-icon"
                  />
                ) : (
                  <img
                    src={el.unSelectedImage}
                    alt={`${el.name}-icon`}
                    className="download-section-1-container-select-btn-icon"
                  />
                )}
                {el.name}
              </div>
            );
          })}
        </div>
        {componentToRender}
      </div>
    </div>
  );
}

export default Download_Section_1;

const TypeMobileSmall = ({ downloadType }) => {
  return (
    <div className="download-section-1-container-content FLEX_COLUMN_ALIGN_CENTER">
      <div className="download-section-1-container-content-text">
        Manage your Ethereum, Volare, USDT, and over 1000+ other coins and
        tokens.
      </div>
      <div style={{ marginTop: 33 }} />
      {downloadType === "Android" ? (
        <Stores use="play-store" />
      ) : (
        <Stores use="app-store" />
      )}
      <div className="download-section-1-container-content-device">
        {downloadType === "Android" ? (
          <img
            src={androidDevice}
            alt="android-divice-img"
            className="download-section-1-container-content-device-img"
          />
        ) : (
          <img
            src={iosDevice}
            alt="ios-divice-img"
            className="download-section-1-container-content-device-img"
          />
        )}
      </div>
    </div>
  );
};
const TypeMobile = ({ downloadType }) => {
  const isMedium = useMediaQuery({ query: "(max-width: 1280px)" });
  return (
    <div className="download-section-1-container-content FLEX_COLUMN_ALIGN_CENTER">
      {isMedium && (
        <div className="download-section-1-container-content-text">
          Manage your Ethereum, Volare, USDT, and over 1000+ other coins and
          tokens.
        </div>
      )}
      <div className="download-section-1-container-content-bottom FLEX_ROW SPACE_BETWEEN">
        {downloadType === "Android" ? (
          <div className="download-section-1-container-content-bottom-left">
            {!isMedium && (
              <>
                <div className="download-section-1-container-content-bottom-left-text1">
                  Connection the Real World to Web3
                </div>
                <div className="download-section-1-container-content-bottom-left-text2">
                  Manage your Ethereum, Volare, USDT, and over 1000+ other coins
                  and tokens.
                </div>
              </>
            )}
            <div className="download-section-1-container-content-bottom-left-text3">
              Size: 29.5M
              <br />
              Version: V2.8.1
              <br />
              OS: Android 5.0 and up
              <br />
              Updated on: 2022-12-29
            </div>
            <Stores use="play-store" />
          </div>
        ) : (
          <div className="download-section-1-container-content-bottom-left">
            {!isMedium && (
              <>
                <div className="download-section-1-container-content-bottom-left-text1">
                  Connection the Real World to Web3
                </div>
                <div className="download-section-1-container-content-bottom-left-text2">
                  Manage your Ethereum, Volare, USDT, and over 1000+ other coins
                  and tokens.
                </div>
              </>
            )}
            <div className="download-section-1-container-content-bottom-left-text3">
              Size: 42.7M
              <br />
              Version: V2.8.1
              <br />
              OS: iOS 11.0 and up
              <br />
              Updated on: 2023-01-02
            </div>
            <Stores use="app-store" />
          </div>
        )}
        <div className="download-section-1-container-content-device">
          {downloadType === "Android" ? (
            <img
              src={androidDevice}
              alt="android-divice-img"
              className="download-section-1-container-content-device-img"
            />
          ) : (
            <img
              src={iosDevice}
              alt="ios-divice-img"
              className="download-section-1-container-content-device-img"
            />
          )}
        </div>
      </div>
    </div>
  );
};
const TypeChrome = () => {
  return <div>Chrome</div>;
};
