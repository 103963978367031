import {newsServer} from './utils/serverInstances';
import {makeQuery} from './utils/makeQuery';

const getNews = async(
    request: GetNewsRequest
): Promise<GetNewsResponse> =>
await newsServer.get({
    path: 'api/v1/news',
    query: makeQuery(request.query)
})
type GetNewsRequest = {
    query: GetNewsQuery;
}
type GetNewsQuery = {
    category?: string;
    hashtag?: string;
    limit: number;
    lastId?: number;
}
export type GetNewsResponse = {
    count: number;
    rows: Row[]
}
type Row = {
    news_id: string;
    category: string;
    date: Date;
    hashtag: string;
    img_url: string;
    text: string;
    title: string;
}
export {
    getNews as getNewsFromServer
}
