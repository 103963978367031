import React, { useState } from "react";
import "./Support_Section_2.scss";
import "./../../styles/common.scss";
import plusIcon from "./../../assets/image/support-section-2/plus-icon.svg";
import minusIcon from "./../../assets/image/support-section-2/minus-icon.svg";
import { FAQs } from "./FAQData";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";

const FAQCategory = [
  {
    FAQ_category_id: 1,
    FAQ_category_name: "General",
  },
  {
    FAQ_category_id: 2,
    FAQ_category_name: "Messanger",
  },
  {
    FAQ_category_id: 3,
    FAQ_category_name: "Wallet",
  },
  {
    FAQ_category_id: 4,
    FAQ_category_name: "Easy Swap",
  },
];
function Support_Section_2() {
  const isSmall = useMediaQuery({ query: "(max-width: 834px)" });
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [openItems, setOpenItems] = useState([]);

  const toggleItem = (index) => {
    if (openItems.includes(index)) {
      setOpenItems(openItems.filter((i) => i !== index));
    } else {
      setOpenItems([...openItems, index]);
    }
  };
  const selectCategory = (id) => {
    setOpenItems([]);
    setSelectedCategory(id);
  };
  return (
    <div className="support-section-2 FLEX_CENTER">
      <div className="support-section-2-container">
        <div className="support-section-2-container-title">FAQs</div>
        <div className="support-section-2-container-faq">
          <div className="support-section-2-container-faq-category FLEX_ROW SPACE_BETWEEN">
            {isSmall ? (
              <>
                <Swiper
                  className={"swiper-container"}
                  slidesPerView={2.5}
                  slideToClickedSlide={true}
                  centeredSlides={true}
                >
                  {FAQCategory.map((el, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div
                          className={
                            selectedCategory === el.FAQ_category_id
                              ? "support-section-2-container-faq-category-item-selected"
                              : "support-section-2-container-faq-category-item"
                          }
                          onClick={() => selectCategory(el.FAQ_category_id)}
                        >
                          {el.FAQ_category_name}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </>
            ) : (
              <>
                {FAQCategory.map((el, index) => {
                  return (
                    <div
                      className={
                        selectedCategory === el.FAQ_category_id
                          ? "support-section-2-container-faq-category-item-selected"
                          : "support-section-2-container-faq-category-item"
                      }
                      key={index}
                      onClick={() => selectCategory(el.FAQ_category_id)}
                    >
                      {el.FAQ_category_name}
                    </div>
                  );
                })}
              </>
            )}
          </div>
          {FAQs.filter((li) => li.FAQ_category_id === selectedCategory).map(
            (el, index) => {
              const isOpen = openItems.includes(index);
              return (
                <div
                  className="support-section-2-container-faq-item"
                  key={index}
                  onClick={() => toggleItem(index)}
                >
                  <div className="support-section-2-container-faq-item-top FLEX_ROW_ALIGN_CENTER SPACE_BETWEEN">
                    <div className="support-section-2-container-faq-item-top-title">
                      {el.title}
                    </div>
                    {isOpen ? (
                      <img
                        src={minusIcon}
                        alt="close"
                        className="support-section-2-container-faq-item-top-btn"
                      />
                    ) : (
                      <img
                        src={plusIcon}
                        alt="open"
                        className="support-section-2-container-faq-item-top-btn"
                      />
                    )}
                  </div>
                  {isOpen && (
                    <div className="support-section-2-container-faq-item-bottom">
                      <div className="support-section-2-container-faq-item-bottom-description">
                        {el.description}
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}

export default Support_Section_2;
