import React, {useEffect, useState} from 'react';
import './Home_Section_6.scss';
import first_image from '../../assets/image/home-section-6/section-6-first.png'
import second_image from '../../assets/image/home-section-6/section-6-second.png'
import third_image from '../../assets/image/home-section-6/section-6-third.png'
import Section_Title from "../../molcules/Section_Title";
import useScrollAnimation from "../../module/animation/useScrollAnimation";
import {useMediaQuery} from "react-responsive";

const contents = [
    {
        text: `Multichain swap on Ethereum, Volare & more`,
        image: first_image,
        alt: 'swap'
    },
    {
        text: `Automatically scan every DEX to get the best deal each time you swap`,
        image: second_image,
        alt: 'dex'
    },
    {
        text: `Find the cheapest and fastest bridge to various networks in seconds`,
        image: third_image,
        alt: 'multi-chain'
    },
]

function Home_Section_6() {
    const isExtraLarge = useMediaQuery({
        query: "(min-width:1280px)",
    });

    useScrollAnimation(['content_0']);
    useScrollAnimation(['content_1'], isExtraLarge? 400: 300);
    useScrollAnimation(['content_2'], isExtraLarge? 500: 300);

    const [activeContentIndex, setActiveContentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveContentIndex(prev => (prev + 1) % 3)
        }, 4000)
        return () => {
            clearInterval(interval)
        }
    }, [activeContentIndex])

    return (
        <div className={'home-section-6 container'}>
            <div className="title">
                <Section_Title text={'Swap more easier'}/>
            </div>
            <div className={'home-section-6-container'} style={{marginTop: 72}}>
                {contents.map((content, index) =>
                    <button className={`content_${index}`}
                        key={`content_${index}`}
                        onClick={() => {
                            setActiveContentIndex(index)
                        }}
                    >
                        <div className={'bar'} style={{
                            ...(index === activeContentIndex && {backgroundColor: '#1D74FF'})
                        }}/>
                        <div className={'text'} style={{
                            ...(index === activeContentIndex && {opacity: 1})
                        }}>{content.text}</div>
                        <img src={content.image} style={{
                            ...(index === activeContentIndex && {opacity: 1})
                        }} alt={content.alt}/>
                    </button>
                )}
            </div>
        </div>
    )
}

export default Home_Section_6;
