import React from "react";
import Download_Section_1 from "../components/download/Download_Section_1";

function Download() {
  return (
    <div>
      <Download_Section_1 />
    </div>
  );
}

export default Download;
