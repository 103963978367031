import React, {useEffect} from 'react';
import './Home_Section_7.scss';
import Stores from "../../molcules/Stores";
import {wordFlick} from "../../module/animation/wordFlick";
import {useMediaQuery} from "react-responsive";

function Home_Section_7() {
    const isSmall = useMediaQuery({
        query: "(min-width:0px) and (max-width:834px)",
    });
    const isMedium = useMediaQuery({
        query: "(min-width:834px) and (max-width:1280px)",
    });
    const isExtraLarge = useMediaQuery({
        query: "(min-width:1280px)",
    });

    useEffect(()=>{
        const interval = wordFlick('store',['iOS', 'Android'])
        return ()=>{
            clearInterval(interval);
        }
    },[])

    return (
        <div className={'home-section-7'}>
            <div className={'container info-wrapper'}>
                <h2 className={'headline'}>
                    Get Whisper for wallet
                </h2>
                <h1 id={'store'} className={'flicker'}>
                    Get Whisper for wallet
                </h1>
                {
                    !isSmall &&
                    <div style={{marginTop: 100}}>
                        <Stores />
                    </div>
                }
            </div>
        </div>
    )
}

export default Home_Section_7;
