import React from 'react';
import './Section_Title.scss';

function Section_Title({text}) {

    return (
        <h1 className={'section-title'}>
            {text}
        </h1>
    )
}

export default Section_Title;
