import React, { useEffect, useMemo, useRef, useState } from "react";
import "./Header.scss";
import whisper_logo from "../assets/image/logo/whisper-logo-text.svg";
import whisper_logo_mobile from "../assets/image/logo/whisper-logo.svg";
import { Link } from "react-router-dom";
import hambergerIcon from "../assets/image/hamberger.png";
import hambergerClose from "../assets/image/hamberger-close.png";

function Header() {
  const menus = useMemo(() => {
    return [
      {
        title: "About us",
        link: "/about",
        color: "#000000",
      },
      {
        title: "Support",
        color: "#000000",
        link: "/support",
      },
      {
        title: "Download",
        link: "/download",
        color: "#1D74FF",
      },
    ];
  }, []);

  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);

  return (
    <>
      <header
        className="header"
        style={{ ...(isOpenMobileMenu && { height: "100%" }) }}
      >
        <div className="header-container container">
          <Link to={"/"} onClick={() => setIsOpenMobileMenu((prev) => false)}>
            <img className="logo medium" src={whisper_logo} alt="logo" />
            <img className="logo mobile" src={whisper_logo_mobile} alt="logo" />
          </Link>
          <div className="header-menu">
            {menus.map((menu, index) => {
              return (
                <Link
                  to={menu.link}
                  className={"menu"}
                  key={`${menu.title}_${index}`}
                >
                  <span style={{ color: menu.color }} className={"menu-text"}>
                    {menu.title}
                  </span>
                </Link>
              );
            })}
          </div>
          <div className="header-hamberger">
            <button onClick={() => setIsOpenMobileMenu((prev) => !prev)}>
              <img
                className="header-hamberger-icon"
                src={isOpenMobileMenu ? hambergerClose : hambergerIcon}
                alt="hamberger"
              />
            </button>
          </div>
        </div>
        {isOpenMobileMenu && (
          <div className="header-mobile-menu">
            {menus.map((menu, index) => (
              <div
                className="header-mobile-menu-row"
                onClick={() => setIsOpenMobileMenu(false)}
              >
                <Link to={menu.link} key={`${menu.title}_${index}`}>
                  <span style={{ color: menu.color }} className={"menu-text"}>
                    {menu.title}
                  </span>
                </Link>
              </div>
            ))}
          </div>
        )}
      </header>
      <div className="header-space" />
    </>
  );
}

export default Header;
