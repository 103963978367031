import React, { useEffect, useRef, useState } from 'react';
import './About_Section_3.scss';
import Section_Title from '../../molcules/Section_Title';
import QRCode from "react-qr-code";
import innerQRIcon from '../../assets/image/about-section-3/inner-qr-icon.png'
import CopyToClipboard from 'react-copy-to-clipboard';
import useScrollAnimation from "../../module/animation/useScrollAnimation";
import icon1 from '../../assets/image/about-section-3/core-contributors/1.png'
import icon2 from '../../assets/image/about-section-3/core-contributors/2.png'
import icon3 from '../../assets/image/about-section-3/core-contributors/3.png'
import icon4 from '../../assets/image/about-section-3/core-contributors/4.png'
import icon5 from '../../assets/image/about-section-3/core-contributors/5.png'
import icon6 from '../../assets/image/about-section-3/core-contributors/6.png'
import { toast } from 'react-hot-toast';


const contributors = [
    {
        id: 1,
        name: 'PJI',
        image: icon1,
        address: '0x1b267fC8d82B2A0DFe924F8Ec1f49059Ec685E42'
    },
    {
        id: 2,
        name: 'JT',
        image: icon2,
        address: '0x766CB8F844E027222b35D7f1CC0e464dEAFEc00a'
    },
    {
        id: 3,
        name: '얼잼',
        image: icon3,
        address: '0xBBcE382E43CED920a5b12eAB9d7671ECa78A52a3'
    },
    {
        id: 4,
        name: 'YJH',
        image: icon4,
        address: '0x4C97A41a3C0DE67d5770A7Ffbba336AB39901fC6'
    },
    {
        id: 5,
        name: 'YHKing',
        image: icon5,
        address: '0xAf788b42786EdF4e0a5e3118CC3Bc82D676332AE'
    },
    {
        id: 6,
        name: 'Carl Yoo',
        image: icon6,
        address: '0xb98DD0eAFf4Ec5B5b706f3fE460e15Ad9C7D296A'
    },
]

function About_Section_3() {

    const [selectedContributor, setSelectedContributor] = useState()
    const [forcedPos, setForcedPos] = useState();
    const ref = useRef(null)

    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setSelectedContributor(undefined)
            setForcedPos(undefined)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])

    useScrollAnimation(['about-section-3']);

    return (
        <div id={'core-contributors'} className={'about-section-3'}>
            <Section_Title text={'Core Contributors'}/>
            <div className='about-section-3-container'>
                {contributors.map(contributor => <div className='about-section-3-circle-wrapper' key={contributor.id}>
                    <button className='about-section-3-circle'
                        key={contributor.id}
                        onClick={(e) => {
                            setSelectedContributor(contributor)
                            if(e.nativeEvent.clientX < 170){
                                setForcedPos({left: 0})
                            }else if(e.nativeEvent.clientX > window.innerWidth - 185){
                                setForcedPos({left: -185})
                            }
                        }}
                    >
                        <img className='about-section-3-circle-image' src={contributor.image} alt={contributor.name}/>
                    </button>
                    {selectedContributor && selectedContributor.id === contributor.id && <div ref={ref}
                        className='about-section-3-selected-contributor'
                        style={{...(forcedPos && forcedPos)}}
                    >
                        <div className='FLEX_ROW_ALIGN_CENTER'>
                            <img className={'about-section-3-selected-contributor-image'} src={selectedContributor.image} alt={selectedContributor.name}/>
                            <div className='about-section-3-selected-contributor-qr-container'>
                                <QRCode
                                    value={contributor.address}
                                    size={104}
                                    bgColor={'#1D74FF'}
                                    fgColor={'#FFFFFF'}
                                    level={'H'}
                                />
                                <img className={'about-section-3-selected-contributor-qr-icon'} src={innerQRIcon} alt={'qr-icon'}/>
                            </div>
                        </div>
                        <div className='about-section-3-selected-contributor-name'>{selectedContributor.name}</div>
                        <div className='about-section-3-selected-contributor-address'>{selectedContributor.address.slice(0, 8)}...{selectedContributor.address.slice(-8)}</div>
                        <CopyToClipboard text={selectedContributor.address} onCopy={() => toast.success("Address copied successfully!")}>
                            <div className='about-section-3-selected-contributor-button'>Copy Address</div>
                        </CopyToClipboard>

                    </div>}
                </div>)}

            </div>
        </div>
    )
}

export default About_Section_3;
