import {Outlet} from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

export const Layout = function () {
    return (
        <>
            <Header/>
            <main>
                <Outlet />
            </main>
            <Footer />
            {/* <div style={{marginTop: 257, marginBottom: 83}}>
            </div> */}
        </>
    );
};
