import axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import {apiErrorCatcher} from './apiErrorCatcher';
import {consoleError} from './console';

type Request = {
  baseURL?: string;
  path: string;
  query?: string;
  data?: object;
  config?: object;
};

export class Server {
  serverInstance: AxiosInstance;

  constructor(baseURL?: string, timeout: number = 10000) {
    this.serverInstance = axios.create({
      baseURL,
      timeout,
    });
  }

  updateRequestHeader(requestHeader: any) {
    this.serverInstance.defaults.headers.common = requestHeader;
  }

  checkRequestHeader() {
    console.log(this.serverInstance.defaults);
  }

  serverResponseInterceptor() {
    this.serverInstance.interceptors.response.use(
      function (response: AxiosResponse) {
        return response;
      },
      function (error: AxiosError) {
        consoleError(error);
        throw error;
      },
    );
  }

  async get({baseURL, path, query = '', config}: Request) {
    try {
      return (await this.serverInstance.get(path + query, {...config, baseURL}))
        .data;
    } catch (e) {
      throw apiErrorCatcher(e);
    }
  }

  async post({baseURL, path, query = '', data, config}: Request) {
    try {
      return (
        await this.serverInstance.post(path + query, data, {...config, baseURL})
      ).data;
    } catch (e) {
      throw apiErrorCatcher(e);
    }
  }

  async delete({baseURL, path, query = '', config}: Request) {
    try {
      return (
        await this.serverInstance.delete(path + query, {...config, baseURL})
      ).data;
    } catch (e) {
      throw apiErrorCatcher(e);
    }
  }

  async put({baseURL, path, query = '', data, config}: Request) {
    try {
      return (
        await this.serverInstance.put(path + query, data, {...config, baseURL})
      ).data;
    } catch (e) {
      throw apiErrorCatcher(e);
    }
  }
}
