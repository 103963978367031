import React, {useMemo} from 'react';
import './Home_Section_8.scss';
import Section_Title from "../../molcules/Section_Title";
import eq_hub_logo from '../../assets/image/logo/eq-hub-logo.svg';
import whisper_logo from '../../assets/image/logo/whisper-logo.svg';
import {useMediaQuery} from "react-responsive";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation} from "swiper";
import ethereum from '../../assets/image/home-section-8/ethereum.png';
import polygon from '../../assets/image/home-section-8/polygon.svg';
import usdt from '../../assets/image/home-section-8/usdt.svg';
import t from '../../assets/image/home-section-8/t.svg';
import volare from '../../assets/image/home-section-8/volare.png';
import useScrollAnimation from "../../module/animation/useScrollAnimation";

function Home_Section_8() {
    const isSmall = useMediaQuery({
        query: "(min-width:0px) and (max-width:834px)",
    });
    const isMedium = useMediaQuery({
        query: "(min-width:834px) and (max-width:1280px)",
    });
    const isExtraLarge = useMediaQuery({
        query: "(min-width:1280px)",
    });

    const TitleComponent = useMemo(()=>{
        const style = {
            color: '#1D74FF',
        }
       return(
           <p>
               We <span style={style}>don't track</span> user data. We <span style={style}>do not store</span> your private keys or seed phrase.
           </p>
       )
    },[]);

    useScrollAnimation(['fade-first',100]);
    useScrollAnimation(['fade-second'], 200);
    useScrollAnimation(['fade-third'], 400);
    useScrollAnimation(['fade-fourth'], 500);
    useScrollAnimation(['fade-fifth'], 550);
    useScrollAnimation(['fade-six'], 700);

    return (
        <div className={'home-section-8 container'}>
            <div className="title">
                <Section_Title text={TitleComponent}/>
            </div>
            <div className={'box-container'} style={{marginTop: 102}}>
                <div className={'box-wrapper box-wrapper-order-1'}>
                    <div className="box box-long fade-first">
                        <h2 className={'title'}>Backed{`\n`}by the best</h2>
                        <img className={'logo-eq-hub'} src={eq_hub_logo} alt="EQHUB"/>
                    </div>
                    <div className="box fade-fourth">
                        <h2 className={'title'}>Completely{`\n`}decentralized</h2>
                        <p className={'desc'}>
                            We don’t cross-associate wallets{`\n`}and don't even ask for your email.
                        </p>
                    </div>
                </div>
                <div className={'box-wrapper box-wrapper-order-2'}>
                    <div className="box box-long-wide fade-second">
                        <h1 className={'title-large'}>
                            Every asset,{`\n`} every chain
                        </h1>
                        <Swiper
                            loop={true}
                            loopFillGroupWithBlank={true}
                            autoplay={{delay: 500}}
                            className={'swiper-container'}
                            modules={[Navigation, Autoplay]}
                            slidesPerView={4}>
                            <SwiperSlide>
                                <img src={ethereum} alt="ethereum" className={'swiper-icon'}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={polygon} alt="polygon" className={'swiper-icon'}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={usdt} alt="usdt" className={'swiper-icon'}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={t} alt="t" className={'swiper-icon'}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={volare} alt="volare" className={'swiper-icon'}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={ethereum} alt="ethereum" className={'swiper-icon'}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={polygon} alt="polygon" className={'swiper-icon'}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={usdt} alt="usdt" className={'swiper-icon'}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={t} alt="t" className={'swiper-icon'}/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={volare} alt="volare" className={'swiper-icon'}/>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    {
                        isExtraLarge &&
                        <div className="box box-short-wide fade-six">
                            <h2 className={'title'}>
                                Track and trade on whatever{`\n`}device you want
                            </h2>
                        </div>
                    }
                </div>
                <div className={'box-wrapper box-wrapper-order-3'}>
                    <div className="box fade-fifth">
                        <h2 className={'title'}>
                            Non custodial
                        </h2>
                        <p className={'desc'}>
                            Nobody can suspend your wallet,{`\n`}freeze your money, or stop your{`\n`}transactions.
                        </p>
                    </div>
                    <div className="box box-long fade-six">
                        <img className={'logo-whisper'} src={whisper_logo} alt="WHISPER"/>
                        <h2 className={'title'} style={{marginTop: 34}}>
                            Every Wallet,{`\n`}One App
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home_Section_8;
