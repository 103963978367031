import React from 'react';
import './Home_Section_3.scss';
import Section_Title from "../../molcules/Section_Title";
import useScrollAnimation from "../../module/animation/useScrollAnimation";


function Home_Section_3() {
    useScrollAnimation(['section-title', 'box-first', 'box-second']);
    useScrollAnimation(['box-third'], 500);

    return (
        <div className={'home-section-3'}>
            <div className={'container section-title'}>
                <Section_Title text={'Supercharged with everything you need'}/>
            </div>
            <div className={'flex-container container'} style={{marginTop: 48}}>
                <div className={'box box-first'}>
                    <h2 className={'box-text'}>
                        Whisper supports  multi-chains and will be added more
                    </h2>
                </div>
                <div className={'box box-half box-second'}>
                    <h2 className={'box-text'}>
                        Swap with various tokens
                    </h2>
                </div>
                <div className={'box box-half box-third'}>
                    <h2 className={'box-text'}>
                        Convenient asset management
                    </h2>
                </div>
            </div>
        </div>
    )
}

export default Home_Section_3;
