export type ApiError = {
  type: 'API';
  data: {
    baseURL: string | undefined;
    path: string | undefined;
    message: string | undefined;
    status: string | undefined;
  };
};
export const isApiError = (error: any): error is ApiError => {
  return error.type === 'API';
};
