import React, { useEffect, useRef, useState } from "react";
import "./Home_Section_10.scss";
import Section_Title from "../../molcules/Section_Title";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import left_arrow from "../../assets/image/swiper/swiper-left-arrow.svg";
import right_arrow from "../../assets/image/swiper/swiper-right-arrow.svg";
import { useMediaQuery } from "react-responsive";
import useScrollAnimation from "../../module/animation/useScrollAnimation";
import { getNewsAction } from "../../../action/newsAction";
import { toast } from "react-hot-toast";

const presNumber = 4;
function Home_Section_10() {
  const isSmall = useMediaQuery({
    query: "(min-width:0px) and (max-width:834px)",
  });
  const isMedium = useMediaQuery({
    query: "(min-width:834px) and (max-width:1280px)",
  });
  const isExtraLarge = useMediaQuery({
    query: "(min-width:1280px)",
  });

  const swiperRef = useRef(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const onBefore = () => {
    swiperRef.current.swiper.slidePrev();
  };
  const onNext = async () => {
    const { result, error } = await getNewsAction(presNumber, pressList[pressList.length - 1].news_id);
    console.log("RESULT ::", result)
    console.log("ERROR ::", error)
    if (error) {
      return;
    }
    const {count, news} = result;
    setPressList(state => [...state, ...news]);
    swiperRef.current.swiper.slideNext();
  };

  const [pressList, setPressList] = useState([]);
  useEffect(() => {
    getNews();
  }, []);

  useScrollAnimation(["home-section-10"], 300);

  const getNews = async () => {
    const { result, error } = await getNewsAction(presNumber);
    console.log("RESULT ::", result)
    console.log("ERROR ::", error)
    if (error) {
      return;
    }
    const {count, news} = result;
    setPressList(news);
  };
  return (
    <div className={"home-section-10"}>
      <div className={"container"}>
        <Section_Title text={"Press Release"} />
      </div>
      <h3 className={"desc"}>Into Blockchain Space</h3>
      <div>
        {(isSmall && pressList) && (
          <div className={"press-container container"}>
            {pressList?.slice(0, 3)?.map((el) => (
              <PressItem image={el.img_url} title={el.title} link={el.link} />
            ))}
          </div>
        )}
        {(isMedium && pressList) && (
          <div className={"press-container container"}>
            {pressList?.slice(0, 6)?.map((el) => (
              <PressItem image={el.img_url} title={el.title} link={el.link} />
            ))}
          </div>
        )}
        {isExtraLarge && (
          <div className={"FLEX_ROW JUSTIFY_CENTER ALIGN_CENTER"} style={{marginTop: 74}}>
            <div className={"swiper-arrow-wrapper swiper-arrow-wrapper-left"}>
              <div
                ref={navigationPrevRef}
                className={"swiper-arrow"}
                onClick={onBefore}
              >
                <img className={"icon"} src={left_arrow} alt="left" />
              </div>
            </div>
            <Swiper
              ref={swiperRef}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              pagination={{
                dynamicBullets: true,
                dynamicMainBullets: 2,
              }}
              speed={500}
              allowTouchMove={true}
              modules={[Navigation]}
              slidesPerView={4}
              className={"pres-release-swiper container"}
            >
              {pressList?.map((el) => (
                <SwiperSlide>
                  <PressItem
                    image={el.img_url}
                    title={el.title}
                    link={el.link}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className={"swiper-arrow-wrapper swiper-arrow-wrapper-right"}>
              <div
                ref={navigationNextRef}
                className={"swiper-arrow"}
                onClick={onNext}
              >
                <img className={"icon"} src={right_arrow} alt="right" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function PressItem({ image, title, link }) {
  return (
    <a className={"press-wrapper"} href={link} target="_blank" rel="noreferrer">
      <img className={"image"} src={image} alt="image" />
      <p className={"title"}>{title}</p>
    </a>
  );
}

export default Home_Section_10;
