import React from "react";
import apple_store from "../assets/image/store/app-store.png";
import google_store from "../assets/image/store/play-store.png";
import './Stores.scss';

export default function Stores ({
    use = 'both'
}) {
    return (
        <div className={'stores FLEX_ROW'}>
            {(use === 'both' || use === 'app-store') && <a href={process.env.REACT_APP_NEWS_APPLE_STORE_URL} target={'_blank'} rel={'noreferrer'}>
                <img className={'apple'} src={apple_store} alt="apple_store"/>
            </a>}
            {(use === 'both' || use === 'play-store') && <a href={process.env.REACT_APP_NEWS_GOOGLE_STORE_URL} target={'_blank'} rel={'noreferrer'}>
                <img className={'google'} src={google_store} alt="google_store"/>
            </a>}
        </div>
    )
}
