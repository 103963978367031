import React, {useEffect} from 'react';
import './Home_Section_5.scss';
import {wordFlick} from "../../module/animation/wordFlick";
import volare from '../../assets/image/home-section-5/volare-icon.png'
import ethereum from '../../assets/image/home-section-5/ethereum-icon.png'
import polygon from '../../assets/image/home-section-5/polygon-icon.png'
import useScrollAnimation from "../../module/animation/useScrollAnimation";

function Home_Section_5() {

    useEffect(()=>{
        const interval = wordFlick('word',[
            'Support various mainnet','Manage crypto assets with the only wallet'])
        return ()=>{
            clearInterval(interval);
        }
    },[])

    useScrollAnimation(['title', 'chain-icon-container', 'word']);

    return (
        <div className={'home-section-5'}>
            <div className={'title-container container'}>
                <p className={'title'}>
                    support various mainnet
                </p>
            </div>
            <div className={'chain-icon-container container'}>
                <img className={'chain-icon'} src={volare} alt="volare"/>
                <img className={'chain-icon'} src={ethereum} alt="ethereum"/>
                <img className={'chain-icon'} src={polygon} alt="polygon"/>
            </div>
            <div className={'word-flicker-container'}>
                <div id={'word'} className={'container word'}/>
            </div>
        </div>
    )
}

export default Home_Section_5;
