import React from 'react';
import './About_Section_2.scss';
import Section_Title from '../../molcules/Section_Title';
import useScrollAnimation from "../../module/animation/useScrollAnimation";


const principles = [
    {
        title: 'Security',
        content: `We don't compromise on security when building features. We use state-of-the-art technologies, and research new security methods and technologies to make strong security guarantees.`
    },
    {
        title: 'Privacy',
        content: `Privacy is the power to selectively reveal oneself to the world. For us, it's essential to protect privacy in both communications and transactions, as well as being a pseudo-anonymous platform. Additionally, we strive to provide the right of total anonymity`
    },
    {
        title: 'Transparency',
        content: `We strive for complete openness and symmetry of information within the organization, and have no border between our core contributors and our community. We are frank about our shortcomings, especially when making short-term tradeoffs in service of our long-term goals.`
    },
    {
        title: 'Decentralization',
        content: `The software we create is a public good. It is made available via a free and open source license, for anyone to share, modify and benefit from. We believe in permission-less participation.`
    },
]

function About_Section_2() {

    return (
        <div id={'our-priciple'} className={'about-section-2'}>
            <div className="title">
                <Section_Title text={'Our Principles'}/>
            </div>
            <div className='about-section-2-container container'>
                {principles.map(principle => <div className='about-section-2-box' key={principle.title}>
                    <div className='about-section-2-box-title'>{principle.title}</div>
                    <div className='about-section-2-box-content'>{principle.content}</div>
                </div>)}
            </div>
        </div>
    )
}

export default About_Section_2;
