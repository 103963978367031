const root = document.documentElement;

const getMousePosition = () => {
    document.addEventListener('mousemove', evt => {
        let x = evt.clientX / window.innerWidth;
        let y = evt.clientY / window.innerHeight;

        root.style.setProperty('--mouse-x', x);
        root.style.setProperty('--mouse-y', y);
    });
}

export default function initAnimation () {
    getMousePosition();
}
