import React from "react";
import Support_Section_1 from "../components/support/Support_Section_1";
import Support_Section_2 from "../components/support/Support_Section_2";

function Support() {
  return (
    <div>
      <Support_Section_1 />
      <Support_Section_2 />
    </div>
  );
}

export default Support;
