import React, {useMemo} from 'react';
import Home_Section_1 from "../components/home/Home_Section_1";
import Home_Section_2 from "../components/home/Home_Section_2";
import Home_Section_3 from "../components/home/Home_Section_3";
import Home_Section_4 from "../components/home/Home_Section_4";
import Home_Section_5 from "../components/home/Home_Section_5";
import Home_Section_6 from "../components/home/Home_Section_6";
import Home_Section_7 from "../components/home/Home_Section_7";
import Home_Section_8 from "../components/home/Home_Section_8";
import Home_Section_9 from "../components/home/Home_Section_9";
import Home_Section_10 from "../components/home/Home_Section_10";
import {useMediaQuery} from "react-responsive";

function Home() {
    const isSmall = useMediaQuery({
        query: "(min-width:0px) and (max-width:834px)",
    });
    const isMedium = useMediaQuery({
        query: "(min-width:834px) and (max-width:1280px)",
    });
    const isExtraLarge = useMediaQuery({
        query: "(min-width:1280px)",
    });
    const viewSize = useMemo(() => {
        if (isSmall) {
            return 'small'
        }
        if (isMedium) {
            return 'medium'
        }
        if (isExtraLarge) {
            return 'extra-large'
        }
        return 'small'
    }, [isSmall, isMedium, isExtraLarge])

    const marginTop = useMemo(() => {
        switch (viewSize) {
            case 'small':
                return 120;
            case 'medium':
                return 196;
            case 'extra-large':
                return 280;
            default:
                return 120;
        }
    }, [viewSize])

    const marginFromFooter = useMemo(() => {
        switch (viewSize) {
            case 'small':
                return 106;
            case 'medium':
                return 213;
            case 'extra-large':
                return 257;
            default:
                return 257;
        }
    }, [viewSize])

    return (
        <div>
            <Home_Section_1/>
            <div style={{marginTop: marginTop}}>
                <Home_Section_2/>
            </div>
             <div style={{marginTop: marginTop}}>
                <Home_Section_3/>
             </div>
            <div style={{marginTop: marginTop}}>
                <Home_Section_4/>
            </div>
            <div style={{marginTop: marginTop}}>
                <Home_Section_5/>
            </div>
            <div style={{marginTop: marginTop}}>
                <Home_Section_6/>
            </div>
            <div style={{marginTop: marginTop}}>
                <Home_Section_7/>
            </div>
            <div style={{marginTop: marginTop}}>
                <Home_Section_8/>
            </div>
            <div style={{marginTop: marginTop}}>
                <Home_Section_9/>
            </div>
            <div style={{marginTop: marginTop, marginBottom: marginFromFooter}}>
                <Home_Section_10/>
            </div>
        </div>
    )
}

export default Home;
