import React from 'react';
import './Home_Section_4.scss';
import Section_Title from "../../molcules/Section_Title";
import diamond from '../../assets/image/home-section-4/diamond.svg';
import useScrollAnimation from "../../module/animation/useScrollAnimation";

function Home_Section_4() {
    useScrollAnimation(['example-container', 'button-support-nft']);
    useScrollAnimation(['button-connect-dApp'], 500);

    return (
        <div className={'home-section-4 container'}>
            <div className="section-title">
                <Section_Title text={
                    'A wallet that supports \n' +
                    'various smart contracts\n' +
                    'such as DApp connection and NFT'}/>
            </div>
            <div className={'example-container'}>
                <div className={'example-title-container'}>
                    <img className={'dia-image'} src={diamond} alt="image"/>
                    <div className={'FLEX_COLUMN'}>
                        <p className={'title-1'}>Non-Fungible Tokens</p>
                        <p className={'title-2'}>A Wallet for NFT Collectors</p>
                    </div>
                </div>
            </div>
            <div className={'button-container'}>
                <div className="button button-support-nft">
                    <p>Support NFT and DynamicNFT</p>
                </div>
                <div className="button button-connect-dApp">
                    <p>Connect with various DApps</p>
                </div>
            </div>
        </div>
    )
}

export default Home_Section_4;
