import {getNewsFromServer, GetNewsResponse} from '../server/newsAPI';
import {ActionType, parseAction} from './util/parseAction';
import {News} from "../reducer/newsType";

export const getNewsAction = (limit: number, lastId?: number, category?: string, hashtag?: string)
    : Promise<ActionType<{ count: number, news: News[] }>> =>
    parseAction(async () => {
        const news: GetNewsResponse = await getNewsFromServer({
            query: {
                category,
                hashtag,
                limit,
                lastId,
            }
        });
        return {
            count: news.count,
            news: news.rows
        };
    });
