import {AxiosError} from 'axios';

const serverErrorConsole = (
  title: string | undefined,
  message: string | number | undefined,
) => {
  console.log(`%c${title}: ${message}`, 'background-color: black; color: red');
};
export const consoleError = (error: AxiosError) => {
  console.group('::server error::');
  serverErrorConsole('baseURL', error.response?.config.baseURL);
  serverErrorConsole('url', error.response?.config.url);
  serverErrorConsole('method', error.response?.config.method);
  serverErrorConsole('status', error.response?.status);
  serverErrorConsole(
    'headers',
    JSON.stringify(error.response?.config.headers, null, 2),
  );
  if (error.response?.data?.stack) {
    delete error.response?.data.stack;
  }
  serverErrorConsole('data', JSON.stringify(error.response?.data, null, 2));
  console.groupEnd();
};
